import { forwardRef } from 'react';

import { FieldError } from '../FieldError';
import { InputTextProps } from '../interface-input';
import { InputTextBase } from './InputTextBase';
import { InputTextWrapper } from './InputTextWrapper';

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (
    { label, size, multiline, withLeftSideIcon, withRightSideIcon, ...inputProps }: InputTextProps,
    refForward,
  ) => {
    return (
      <>
        <InputTextWrapper
          size={size}
          withLabel={!!label}
          multiline={multiline}
          withLeftSideIcon={withLeftSideIcon}
          withRightSideIcon={withRightSideIcon}
        >
          <InputTextBase
            label={label}
            {...inputProps}
            ref={refForward}
            multiline={multiline}
            size={size}
          />
        </InputTextWrapper>
        <FieldError {...inputProps} />
      </>
    );
  },
);

InputText.displayName = 'InputText';
