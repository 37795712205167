import { useContextGR } from '@google-recaptcha';
import { useEventEmailCaptured } from '@google-tag-manager';
import useTranslation from 'next-translate/useTranslation';

import { SubscribeService } from '@core/api';
import {
  Box,
  ButtonSubmit,
  FormControl,
  Grid,
  Image,
  InputTextControl,
  Typography,
  spotlightBannerCollapsingAnimation,
} from '@core/component';
import { Translate } from '@core/constant';
import { useContextUser, useContextUtil } from '@core/context';
import { useLanguage } from '@core/hook';
import { getStaticPathIconFolder } from '@core/media';
import { EColor, EGTMCaptureLocation, ETypographyVariant } from '@core/type';

import { SubmitSuccessful } from './SubmitSuccessful';
import { ANIMATION_TIME } from './constants';
import { FormProps, InputForm } from './interface-spotlight-banner';
import { useSpotlightForm } from './use-utils-form';

export const Form = ({ onSuccessfulAnimationEnd }: FormProps) => {
  const { getRecaptcha } = useContextGR();
  const { email: emailUser } = useContextUser();
  const { axiosInstance } = useContextUtil();
  const { language } = useLanguage();
  const { pushEventEmailCaptured } = useEventEmailCaptured();

  const { t } = useTranslation(Translate.common.PRODUCT);

  const {
    isClosed,
    setIsClosed,
    isClosing,
    setIsClosing,
    control,
    handleSubmit,
    trigger,
    reset,
    setError,
    isSubmitSuccessful,
    tForm,
    onSuccess,
  } = useSpotlightForm();

  if (isClosed) {
    return null;
  }

  return (
    <Box
      position="relative"
      borderRadius="16px"
      backgroundColor={EColor.INFO}
      padding="40px"
      animation={isClosing && spotlightBannerCollapsingAnimation}
      animationDuration={`${ANIMATION_TIME}ms`}
    >
      <FormControl
        t={tForm}
        trigger={trigger}
        reset={reset}
        handleSubmit={handleSubmit}
        setError={setError}
        fn={async (data: InputForm) => {
          const token = await getRecaptcha();
          const submitData = {
            ...data,
            token,
          };
          return SubscribeService.subscribeEmail(axiosInstance, language, submitData).then(
            (data) => {
              pushEventEmailCaptured(EGTMCaptureLocation.NEWSLETTER);
              return data;
            },
          );
        }}
        onSuccess={onSuccess}
      >
        <Box position="absolute" left="50%" top="30px" transform="translateX(-50%)">
          <Image
            src={getStaticPathIconFolder('spotlight/image-letter.svg')}
            width="265"
            height="65"
            alt="image-letter"
          />
        </Box>
        {isSubmitSuccessful ? (
          <SubmitSuccessful
            setIsClosed={setIsClosed}
            setIsClosing={setIsClosing}
            onSuccessfulAnimationEnd={onSuccessfulAnimationEnd}
          />
        ) : (
          <>
            <Box position="relative" marginTop="24px" marginBottom="12px">
              <Typography fontStyle="italic" variant={ETypographyVariant.H3} textAlign="center">
                {t('getSpotlight')}
              </Typography>
            </Box>
            <Typography variant={ETypographyVariant.BODY2} textAlign="center" marginBottom="24px">
              {t('subscribe.description')}
            </Typography>

            <Box maxWidth="500px" margin="0 auto" display="flex" justifyContent="center">
              {emailUser ? (
                <ButtonSubmit>{t('subscribe.buttonSubscribe')}</ButtonSubmit>
              ) : (
                <Grid isContainer columns={12} columnSpacing={8} rowSpacing={16}>
                  <Grid md={8}>
                    <InputTextControl
                      control={control}
                      name="email"
                      label={tForm('email.placeholder')}
                    />
                  </Grid>
                  <Grid md={4}>
                    <Box display="flex" justifyContent="center">
                      <ButtonSubmit>{t('subscribe.buttonSubscribe')}</ButtonSubmit>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          </>
        )}
      </FormControl>
    </Box>
  );
};
