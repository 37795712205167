import useTranslation from 'next-translate/useTranslation';

import { Box, Button, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { EButtonVariant, EFontWeight, ESize, ETypographyVariant } from '@core/type';

import { SubmitSuccessfulProps } from './interface-spotlight-banner';

const ANIMATION_TIME = 300;

export const SubmitSuccessful = ({
  setIsClosed,
  setIsClosing,
  onSuccessfulAnimationEnd,
  isSmallBanner,
}: SubmitSuccessfulProps) => {
  const { t: tButton } = useTranslation(Translate.common.BUTTON);
  const { t: tProduct } = useTranslation(Translate.common.PRODUCT);

  return (
    <>
      <Box margin={isSmallBanner ? '16px 0 8px' : '24px 0 12px'} position={'relative'}>
        <Typography
          fontStyle={'italic'}
          variant={isSmallBanner ? ETypographyVariant.BODY2 : ETypographyVariant.H3}
          fontWeight={isSmallBanner ? EFontWeight.BOLD : EFontWeight.REGULAR}
          textAlign={'center'}
        >
          {tProduct('successSubscribed.thanks')}
        </Typography>
      </Box>
      <Typography
        variant={isSmallBanner ? ETypographyVariant.CAPTION1 : ETypographyVariant.BODY2}
        textAlign={'center'}
        marginBottom={'24px'}
      >
        {tProduct('successfullySubscribed')}
      </Typography>
      <Box display={'flex'} justifyContent={'center'}>
        <Button
          size={isSmallBanner ? ESize.SM : undefined}
          padding={isSmallBanner ? '0 20px' : undefined}
          type={'button'}
          variant={EButtonVariant.OUTLINED}
          onClick={() => {
            setTimeout(() => {
              if (onSuccessfulAnimationEnd) {
                onSuccessfulAnimationEnd();
              }

              setIsClosed(true);
            }, ANIMATION_TIME);

            setIsClosing(true);
          }}
        >
          {tButton('close.title')}
        </Button>
      </Box>
    </>
  );
};
