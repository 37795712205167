import { useCallback, useEffect, useState } from 'react';

import { ImageApiProps } from '@core/type/api';

import { Image } from './Image';

export const ImageApi = ({
  webp,
  src,
  alt,
  defaultImage,
  defaultImageBlurData,
  ...props
}: ImageApiProps) => {
  const baseSource = webp?.baseUrl || src?.baseUrl;
  const rawSource = webp?.rawUrl || src?.rawUrl;

  const [imgSrc, setImgSrc] = useState<string | undefined>(baseSource || rawSource);

  const blurDataURL = webp?.blurDataUrl || src?.blurDataUrl;

  const handleError = useCallback(() => {
    if (defaultImage) {
      setImgSrc(defaultImage);
    }
  }, [defaultImage]);

  const handleLoad = useCallback(() => {
    // if baseSource is successfully loaded we try to set rawSource (higher quality image) as image src
    if (rawSource || baseSource) {
      setImgSrc(rawSource || baseSource);
    }
  }, [baseSource, rawSource]);

  useEffect(handleLoad, [handleLoad]);

  return (
    <Image
      src={imgSrc || defaultImage}
      placeholder={blurDataURL ? 'blur' : 'empty'}
      blurDataURL={blurDataURL || defaultImageBlurData}
      onError={handleError}
      alt={alt}
      onLoad={handleLoad}
      {...props}
    />
  );
};
